import { userinfo, updateuserinfo, avatar } from "../../api/application/index";
export default {
  data() {
    return {
      value: '',
      imgurl: ''
    };
  },
  methods: {
    afterRead(file) {
      this.uploadhukou(file.file);
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    // 代理资料上传
    uploadhukou(file) {
      $Toast.loading({
        message: '上传中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      let formData = new FormData();
      // console.log(file);
      formData.append("file", file);
      avatar({
        avatarfile: formData
      }).then(res => {
        console.log(res);
        $Toast.clear();
        if (res.status == 1) {}
      });
    },
    onClickLeft() {
      this.$router.back(-1);
    },
    add() {
      if (this.value == '') {
        $Toast('请先输入新的名称');
        return;
      }
      updateuserinfo({
        name: this.value
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.newname = '';
          this.nameshow = false;
          $Toast('修改成功');
          setTimeout(() => {
            this.$router.back(-1);
          }, 1200);
        }
      });
    }
  }
};