import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "修改个人信息",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $options.onClickLeft
  }, null, 8, ["onClickLeft"]), _createVNode(_component_van_cell_group, null, {
    default: _withCtx(() => [_createVNode(_component_van_field, {
      modelValue: $data.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.value = $event),
      label: "昵称",
      placeholder: "请输入新的昵称"
    }, null, 8, ["modelValue"])]),
    _: 1
  }), _createElementVNode("div", {
    class: "out",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.add && $options.add(...args))
  }, " 确认 ")], 64);
}